import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {List, Steps, Radio, Button} from "antd-mobile";
import {CollectMoneyOutline, FillinOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

import Style from "./Type.module.css";

const RecruitType = (props) => {
    const store = useSelector((store) => store);
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [recruitList, setRecruitList] = useState([]);

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "报名系统", recruitTermId: props.match.params.recruitTermId});

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.recruit.recruitParentName) {
            handleLoadRecruit();
        }

        //eslint-disable-next-line
    }, [store.recruit.recruitParentName]);

    const handleLoadRecruit = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/consumer/mobile/v1/list",
            data: {
                recruitTermId: props.match.params.recruitTermId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
            },
            success: (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].active = false;
                }
                setRecruitList(response.data);
            },
            error: (response) => {
                setIsError(true);
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={isError} isError={store.recruit.isError}>
                <List>
                    <List.Item>
                        <Steps current={0}>
                            <Steps.Step title="选择类型" description="" icon={<CollectMoneyOutline />} />
                            <Steps.Step title="填写信息" description="" icon={<FillinOutline />} />
                        </Steps>
                    </List.Item>
                </List>
                <div className={Style.empty}></div>
                <List>
                    {recruitList.map((recruit, recruitIndex) => {
                        return (
                            <List.Item
                                key={recruitIndex}
                                arrow={false}
                                onClick={() => {
                                    for (let i = 0; i < recruitList.length; i++) {
                                        recruitList[i].active = i == recruitIndex;
                                    }

                                    setIsSubmit(true);
                                    setRecruitList([...recruitList]);
                                }}
                            >
                                <Radio checked={recruit.active}>{recruit.recruitName}</Radio>
                            </List.Item>
                        );
                    })}
                </List>
            </ChuangShiOS.Content>
            {isLaunch ? (
                <List>
                    <List.Item
                        arrow={false}
                        onClick={() => {
                            let recruitId = "";
                            for (let i = 0; i < recruitList.length; i++) {
                                if (recruitList[i].active) {
                                    recruitId = recruitList[i].recruitId;
                                }
                            }
                            ChuangShiOS.Util.redirectTo(props, {
                                url: "/edu/recruit/add/" + props.match.params.recruitTermId + "/" + recruitId,
                            });
                        }}
                    >
                        <Button block color="primary" disabled={!isSubmit}>
                            下一步
                        </Button>
                    </List.Item>
                </List>
            ) : null}
        </ChuangShiOS.Page>
    );
};

RecruitType.propTypes = {};

RecruitType.defaultProps = {};

export default withRouter(RecruitType);
