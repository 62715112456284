import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {List, Button} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

// import Style from "./Add.module.css";

const RecruitAdd = (props) => {
    const store = useSelector((store) => store);
    const render = useRef();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isError, setIsError] = useState(false);
    const [status, setStatus] = useState("");
    const [recruitStudentStatus, setRecruitStudentStatus] = useState("");
    const [systemVersion, setSystemVersion] = useState({});

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "报名系统", recruitTermId: props.match.params.recruitTermId});

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.recruit.recruitParentName) {
            handleLoadRecruitStudent();
        }

        //eslint-disable-next-line
    }, [store.recruit.recruitParentName]);

    const handleLoadRecruitStudent = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/student/consumer/mobile/v1/find",
            data: {
                recruitStudentId: props.match.params.recruitStudentId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
            },
            success: (response) => {
                render.current.setDataAndValue(response.data.recruitForm, response.data.recruitStudentData);

                setStatus(response.data.recruitStudentStatusValue);
                setRecruitStudentStatus(response.data.recruitStudentStatus);
                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                setIsError(true);
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleSubmit = (recruitStudentData) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.toastLoad();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/student/consumer/mobile/v1/update",
            data: {
                recruitStudentId: props.match.params.recruitStudentId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
                recruitStudentData: recruitStudentData,
                systemVersion: systemVersion,
            },
            success: (response) => {
                ChuangShiOS.Util.toastSuccess({
                    message: response.message,
                    complete: () => {},
                });

                setTimeout(() => {
                    ChuangShiOS.Util.navigateBack(props, {});
                }, 2000);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} isError={store.recruit.isError || isError}>
                <ChuangShiOS.Render ref={render} status={status} readonly={false}></ChuangShiOS.Render>
            </ChuangShiOS.Content>
            {isLaunch ? (
                recruitStudentStatus == "PAID" ? null : (
                    <List>
                        <List.Item
                            arrow={false}
                            onClick={() => {
                                render.current
                                    .getForm()
                                    .validateFields()
                                    .then((valueMap) => {
                                        handleSubmit(render.current.getData(valueMap));
                                    })
                                    .catch((response) => {});
                            }}
                        >
                            <Button block color="primary">
                                提交
                            </Button>
                        </List.Item>
                    </List>
                )
            ) : null}
        </ChuangShiOS.Page>
    );
};

RecruitAdd.propTypes = {};

RecruitAdd.defaultProps = {};

export default withRouter(RecruitAdd);
