const data = {
    host: "https://api.nowui.com",
    baseHost: "https://api.nowui.com",
    imageUploadHost: "https://api.nowui.com",
    imagePreviewHost: "https://static.nowui.com",
    appId: "",
    version: "1.0.0",
    platform: "ChuangShi-OS-H5",
    homePath: "",
    loginPath: "",
    registerPath: "",
    tokenKey: "consumer_mobile_token_",
};

export default data;
