import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {List, ActionSheet} from "antd-mobile";
import {ShopbagOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

import Style from "./My.module.css";

const RecruitIndex = (props) => {
    const store = useSelector((store) => store);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isActionSheet, setIsActionSheet] = useState(false);

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "个人中心", recruitTermId: props.match.params.recruitTermId});

        setIsLaunch(true);

        //eslint-disable-next-line
    }, []);

    useActivate(() => {
        document.title = "个人中心";
    });

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} isError={store.recruit.isError}>
                <List>
                    <List.Item clickable arrow>
                        <div className={Style.user}>
                            {store.main.config.schoolImage ? (
                                <img
                                    className={Style.user_image}
                                    src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(store.main.config.schoolImage, 110)}
                                    alt=""
                                />
                            ) : (
                                <div className={Style.user_image}></div>
                            )}
                            <div>
                                <div className={Style.user_name}>{store.main.student.studentName}</div>
                                <div className={Style.user_info}>
                                    <span className={Style.user_clazz}>班级: {store.main.student.clazzName}</span>
                                </div>
                            </div>
                        </div>
                    </List.Item>
                </List>
                <List className={Style.list}>
                    <List.Item
                        prefix={<ShopbagOutline className={Style.list_icon} />}
                        clickable
                        arrow
                        onClick={() => {
                            ChuangShiOS.Util.navigateTo(props, {
                                url: "/edu/course/my/" + props.match.params.recruitTermId,
                            });
                        }}
                    >
                        我的课程
                    </List.Item>
                    {/* <List.Item prefix={<LockOutline className={Style.list_icon} />} clickable arrow>
                        修改密码
                    </List.Item> */}
                </List>
                {/* <div className={Style.button}>
                    <Button
                        block
                        onClick={() => {
                            setIsActionSheet(true);
                        }}
                    >
                        退出登录
                    </Button>
                </div> */}
            </ChuangShiOS.Content>
            <ActionSheet
                visible={isActionSheet}
                actions={[
                    {
                        text: "退出登录",
                        key: "delete",
                        danger: true,
                        onClick: () => {
                            setIsActionSheet(false);

                            ChuangShiOS.Util.removeToken();

                            ChuangShiOS.Util.toastSuccess({
                                message: "退出成功",
                                complete: () => {},
                            });

                            setTimeout(() => {
                                ChuangShiOS.Util.redirectTo(props, {
                                    url: "/edu/login/index/" + props.match.params.recruitTermId,
                                });
                            }, 2000);
                        },
                    },
                ]}
                cancelText="取消"
                onClose={() => setIsActionSheet(false)}
            />
        </ChuangShiOS.Page>
    );
};

RecruitIndex.propTypes = {};

RecruitIndex.defaultProps = {};

export default withRouter(RecruitIndex);
