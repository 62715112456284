const initialState = {
    configMpAppId: "",
    isError: false,
    schoolName: "",
    schoolImage: "",
    recruitParentName: "",
    recruitParentAvatar: "",
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "recruit":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "recruit",
    reducer: reducer,
};

export default data;
