import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {List, Button, Image, ActionSheet} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

import Style from "./Profile.module.css";

const RecruitProfile = (props) => {
    const dispatch = useDispatch();
    const store = useSelector((store) => store);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isActionSheet, setIsActionSheet] = useState(false);

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "报名系统", recruitTermId: props.match.params.recruitTermId});

        let token = localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId);
        if (token) {
        } else {
            setIsLaunch(true);
        }

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.recruit.recruitParentName) {
            setIsLaunch(true);
        }

        //eslint-disable-next-line
    }, [store.recruit.recruitParentName]);

    useActivate(() => {
        document.title = "报名系统";
    });

    useUnactivate(() => {});

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} emptyDescription={"暂无报名信息"} isError={store.recruit.isError}>
                <List>
                    <List.Item
                        clickable
                        arrow={false}
                        extra={
                            <Image
                                className={Style.user_image}
                                src={Application.imagePreviewHost + store.recruit.recruitParentAvatar}
                                fit="contain"
                                lazy
                            />
                        }
                    >
                        头像
                    </List.Item>
                    <List.Item clickable arrow={false} extra={store.recruit.recruitParentName}>
                        名称
                    </List.Item>
                    <List.Item clickable arrow={false} extra={store.recruit.schoolName}>
                        学校
                    </List.Item>
                </List>
                <div className={Style.button}>
                    <Button
                        block
                        onClick={() => {
                            setIsActionSheet(true);
                        }}
                    >
                        退出登录
                    </Button>
                </div>
            </ChuangShiOS.Content>
            <ActionSheet
                visible={isActionSheet}
                actions={[
                    {
                        text: "退出登录",
                        key: "delete",
                        danger: true,
                        onClick: () => {
                            setIsActionSheet(false);

                            localStorage.removeItem("edu_recruit_" + props.match.params.recruitTermId);

                            dispatch({
                                type: "recruit",
                                data: {
                                    recruitParentName: "",
                                    recruitParentAvatar: "",
                                },
                            });

                            ChuangShiOS.Util.toastSuccess({
                                message: "退出成功",
                                complete: () => {},
                            });

                            setTimeout(() => {
                                ChuangShiOS.Util.navigateBack(props, {});
                            }, 2000);
                        },
                    },
                ]}
                cancelText="取消"
                onClose={() => setIsActionSheet(false)}
            />
        </ChuangShiOS.Page>
    );
};

RecruitProfile.propTypes = {};

RecruitProfile.defaultProps = {};

export default withRouter(RecruitProfile);
