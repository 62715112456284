import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

let routers = [];
let routerContexts = require.context("./", true, /^.*\/Router\.js$/);
routerContexts.keys().forEach((fileName) => {
    routers = routers.concat(routerContexts(fileName).default);
});

const MainIndex = (props) => {
    const dispatch = useDispatch();
    const [recruitTermId, setRecruitTermId] = useState("");

    ChuangShiOS.Notification.remove("recruit_init", this);
    ChuangShiOS.Notification.on("recruit_init", this, (data) => {
        if (data.title) {
            document.title = data.title;
        }

        if (data.recruitTermId && data.recruitTermId != recruitTermId) {
            setRecruitTermId(data.recruitTermId);

            handleLoadRecruitTerm(data.recruitTermId);
        }
    });

    useEffect(() => {
        let recruitTeacherId = ChuangShiOS.Util.getParameter("recruitTeacherId");
        if (recruitTeacherId) {
            localStorage.setItem("recruitTeacherId", recruitTeacherId);
        }

        return () => {
            ChuangShiOS.Notification.remove("recruit_init", this);
        };

        //eslint-disable-next-line
    }, []);

    const handleLoadRecruitTerm = (recruitTermId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/term/consumer/mobile/v1/find",
            data: {
                recruitTermId: recruitTermId,
                token: localStorage.getItem("edu_recruit_" + recruitTermId),
            },
            success: (response) => {
                Application.appId = response.data.appId;
                ChuangShiOS.Util.setApplication(Application);

                dispatch({
                    type: "recruit",
                    data: {
                        configMpAppId: response.data.configMpAppId,
                        schoolName: response.data.schoolName,
                        schoolImage: response.data.schoolImage,
                        recruitParentName: response.data.recruitParentName,
                        recruitParentAvatar: response.data.recruitParentAvatar,
                    },
                });
            },
            error: (response) => {
                dispatch({
                    type: "recruit",
                    data: {
                        isError: true,
                    },
                });
            },
            complete: (response) => {},
        });
    };

    return (
        <React.Fragment>
            {routers.map((router) => {
                return router;
            })}
        </React.Fragment>
    );
};

MainIndex.propTypes = {};

MainIndex.defaultProps = {};

export default withRouter(MainIndex);
