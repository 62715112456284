import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {List, Steps, Form, Input, TextArea, Radio, Space, DatePicker, Button} from "antd-mobile";
import {CollectMoneyOutline, FillinOutline} from "antd-mobile-icons";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

import Style from "./Add.module.css";

const RecruitAdd = (props) => {
    const store = useSelector((store) => store);
    const render = useRef();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isError, setIsError] = useState(false);
    const [recruit, setRecruit] = useState({
        recruitForm: {
            type: "form",
            layout: "vertical",
            backgroundColor: "#ffffff",
            componentList: [],
        },
    });

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "报名系统", recruitTermId: props.match.params.recruitTermId});

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.recruit.recruitParentName) {
            handleLoadRecruit();
        }

        //eslint-disable-next-line
    }, [store.recruit.recruitParentName]);

    const handleLoadRecruit = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/consumer/mobile/v1/find",
            data: {
                recruitTermId: props.match.params.recruitTermId,
                recruitId: props.match.params.recruitId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
            },
            success: (response) => {
                setRecruit(response.data);

                render.current.setData(response.data.recruitForm);
            },
            error: (response) => {
                setIsError(true);
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleSubmit = (recruitStudentData) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.toastLoad();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/student/consumer/mobile/v1/save",
            data: {
                recruitTermId: props.match.params.recruitTermId,
                recruitId: props.match.params.recruitId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
                recruitStudentData: recruitStudentData,
                recruitTeacherId: localStorage.getItem("recruitTeacherId") ? localStorage.getItem("recruitTeacherId") : "",
            },
            success: (response) => {
                ChuangShiOS.Util.toastSuccess({
                    message: response.message,
                    complete: () => {},
                });

                setTimeout(() => {
                    ChuangShiOS.Util.navigateBack(props, {});
                }, 2000);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} isError={store.recruit.isError || isError}>
                <List>
                    <List.Item>
                        <Steps current={1}>
                            <Steps.Step title="选择类型" description="" icon={<CollectMoneyOutline />} />
                            <Steps.Step title="填写信息" description="" icon={<FillinOutline />} />
                        </Steps>
                    </List.Item>
                </List>
                <div className={Style.empty}></div>
                <ChuangShiOS.Render ref={render}></ChuangShiOS.Render>
            </ChuangShiOS.Content>
            {isLaunch ? (
                <List>
                    <List.Item
                        arrow={false}
                        onClick={() => {
                            render.current
                                .getForm()
                                .validateFields()
                                .then((valueMap) => {
                                    handleSubmit(render.current.getData(valueMap));
                                })
                                .catch((response) => {});
                        }}
                    >
                        <Button block color="primary">
                            提交
                        </Button>
                    </List.Item>
                </List>
            ) : null}
        </ChuangShiOS.Page>
    );
};

RecruitAdd.propTypes = {};

RecruitAdd.defaultProps = {};

export default withRouter(RecruitAdd);
