import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route} from "react-router-dom";
import {createStore, combineReducers} from "redux";
import {Provider} from "react-redux";
import {routerReducer} from "react-router-redux";
import {AliveScope} from "react-activation";
import ChuangShiOS from "chuangshi-os-mobile-component";
import reportWebVitals from "./reportWebVitals";

import Application from "./common/Application";

import MainIndex from "./view/main/Index";

import "./index.css";

window.process = {};

ChuangShiOS.Util.setApplication(Application);

const reducers = {};
let storeContexts = require.context("./view/", true, /^.*\/Store\.js$/);
storeContexts.keys().forEach((fileName) => {
    reducers[storeContexts(fileName).default.name] = storeContexts(fileName).default.reducer;
});

const stores = createStore(
    combineReducers(
        Object.assign(reducers, {
            routing: routerReducer,
        })
    )
);

ReactDOM.render(
    <React.Fragment>
        <Provider store={stores}>
            <HashRouter>
                <AliveScope>
                    <Route path="/" component={MainIndex}></Route>
                </AliveScope>
            </HashRouter>
        </Provider>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
