import React from "react";
import {Route} from "react-router-dom";

import RecruitIndex from "./Index";
import RecruitType from "./Type";
import RecruitAdd from "./Add";
import RecruitMy from "./My";
import RecruitAuth from "./Auth";
import RecruitProfile from "./Profile";
import RecruitEdit from "./Edit";

const data = [
    <Route
        exact
        key="RecruitIndex"
        path="/edu/recruit/index/:recruitTermId"
        render={(props) => {
            return <RecruitIndex {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitType"
        path="/edu/recruit/type/:recruitTermId"
        render={(props) => {
            return <RecruitType {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitAdd"
        path="/edu/recruit/add/:recruitTermId/:recruitId"
        render={(props) => {
            return <RecruitAdd {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitMy"
        path="/edu/recruit/my/:recruitTermId"
        render={(props) => {
            return <RecruitMy {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitAuth"
        path="/edu/recruit/auth/:recruitTermId"
        render={(props) => {
            return <RecruitAuth {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitProfile"
        path="/edu/recruit/profile/:recruitTermId"
        render={(props) => {
            return <RecruitProfile {...props} />;
        }}
    />,
    <Route
        exact
        key="RecruitEdit"
        path="/edu/recruit/edit/:recruitTermId/:recruitStudentId"
        render={(props) => {
            return <RecruitEdit {...props} />;
        }}
    />,
];

export default data;
