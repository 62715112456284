import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {List, Button, Image} from "antd-mobile";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

import Style from "./My.module.css";

const RecruitMy = (props) => {
    const store = useSelector((store) => store);
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [recruitStudentList, setRecruitStudentList] = useState([]);

    useEffect(() => {
        ChuangShiOS.Notification.emit("recruit_init", {title: "报名系统", recruitTermId: props.match.params.recruitTermId});

        let token = localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId);
        if (token) {
        } else {
            setIsLaunch(true);
        }

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.recruit.recruitParentName) {
            setIsLogin(true);

            handleLoadRecruitStudent();
        } else {
            setIsEmpty(true);
        }

        //eslint-disable-next-line
    }, [store.recruit.recruitParentName]);

    useActivate(() => {
        document.title = "报名系统";
    });

    useUnactivate(() => {});

    const handleAuth = () => {
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            store.recruit.configMpAppId +
            "&redirect_uri=" +
            encodeURIComponent("https://h5.nowui.com/#/edu/recruit/auth/" + props.match.params.recruitTermId) +
            "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    };

    const handleLoadRecruitStudent = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/student/consumer/mobile/v1/list",
            data: {
                recruitTermId: props.match.params.recruitTermId,
                token: localStorage.getItem("edu_recruit_" + props.match.params.recruitTermId),
            },
            success: (response) => {
                setIsEmpty(response.data.length == 0);
                setRecruitStudentList(response.data);
            },
            error: (response) => {},
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false} emptyDescription={"暂无报名信息"} isError={store.recruit.isError}>
                <div className={Style.page}>
                    <List>
                        <List.Item
                            clickable
                            arrow={false}
                            onClick={() => {
                                if (isLogin) {
                                    ChuangShiOS.Util.navigateTo(props, {
                                        url: "/edu/recruit/profile/" + props.match.params.recruitTermId,
                                    });
                                } else {
                                    handleAuth();
                                }
                            }}
                        >
                            {isLogin ? (
                                <React.Fragment>
                                    <div className={Style.user}>
                                        <Image
                                            className={Style.user_image}
                                            src={Application.imagePreviewHost + store.recruit.recruitParentAvatar}
                                            fit="contain"
                                            lazy
                                        />
                                        <div>
                                            <div className={Style.user_name}>{store.recruit.recruitParentName}</div>
                                            <div className={Style.user_info}>{store.recruit.schoolName}</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className={Style.login_title}>登录/注册</div>
                                    <div className={Style.login_tips}>登录后进行报名</div>
                                </React.Fragment>
                            )}
                        </List.Item>
                    </List>
                    <ChuangShiOS.Content isLaunch={true} isEmpty={isEmpty} emptyDescription={"暂无报名信息"}>
                        <List
                            className={Style.list}
                            style={{
                                "--border-top": "0px #eee solid",
                            }}
                        >
                            {recruitStudentList.map((recruitStudent, recruitStudentIndex) => {
                                return (
                                    <List.Item
                                        key={recruitStudentIndex}
                                        arrow
                                        onClick={() => {
                                            ChuangShiOS.Util.navigateTo(props, {
                                                url: "/edu/recruit/edit/" + props.match.params.recruitTermId + "/" + recruitStudent.recruitStudentId,
                                            });
                                        }}
                                    >
                                        <div className={Style.empty}></div>
                                        <div className={Style.row}>
                                            <div className={Style.label}>学生姓名:</div>
                                            <div className={Style.value}>{recruitStudent.studentName}</div>
                                        </div>
                                        <div className={Style.empty}></div>
                                        <div className={Style.row}>
                                            <div className={Style.label}>{recruitStudent.certificateType}:</div>
                                            <div className={Style.value}>{recruitStudent.certificateNumber}</div>
                                        </div>
                                        {/* <div className={Style.row}>
                                            <div className={Style.label}>报名:</div>
                                            <div className={Style.value}>{recruitStudent.recruitName}</div>
                                        </div>
                                        <div className={Style.empty}></div>
                                        <div className={Style.row}>
                                            <div className={Style.label}>状态:</div>
                                            <div className={Style.value}>{recruitStudent.recruitStudentStatusValue}</div>
                                        </div> */}
                                        {recruitStudent.gradeName ? (
                                            <React.Fragment>
                                                <div className={Style.empty}></div>
                                                <div className={Style.row}>
                                                    <div className={Style.label}>分班年级:</div>
                                                    <div className={Style.value}>{recruitStudent.gradeName}</div>
                                                </div>
                                                <div className={Style.empty}></div>
                                                <div className={Style.row}>
                                                    <div className={Style.label}>分班班级:</div>
                                                    <div className={Style.value}>{recruitStudent.clazzName}</div>
                                                </div>
                                                <div className={Style.empty}></div>
                                                <div className={Style.row}>
                                                    <div className={Style.label}>导师名字:</div>
                                                    <div className={Style.value}>{recruitStudent.teacherName}</div>
                                                </div>
                                                <div className={Style.empty}></div>
                                                <div className={Style.row}>
                                                    <div className={Style.label}>导师电话:</div>
                                                    <div className={Style.value}>{recruitStudent.teacherPhone}</div>
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                    </List.Item>
                                );
                            })}
                        </List>
                    </ChuangShiOS.Content>
                    {isLogin ? (
                        <List>
                            <List.Item
                                arrow={false}
                                onClick={() => {
                                    ChuangShiOS.Util.navigateTo(props, {
                                        url: "/edu/recruit/type/" + props.match.params.recruitTermId,
                                    });
                                }}
                            >
                                <Button block color="primary">
                                    我要报名
                                </Button>
                            </List.Item>
                        </List>
                    ) : null}
                </div>
            </ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

RecruitMy.propTypes = {};

RecruitMy.defaultProps = {};

export default withRouter(RecruitMy);
