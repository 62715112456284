import React, {useEffect} from "react";
import {withRouter, Route} from "react-router-dom";

import RecruitMain from "../edu/recruit/Main";

const MainIndex = (props) => {
    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Route path="/edu/recruit/" component={RecruitMain}></Route>
        </React.Fragment>
    );
};

MainIndex.propTypes = {};

MainIndex.defaultProps = {};

export default withRouter(MainIndex);
