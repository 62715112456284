import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useActivate, useUnactivate} from "react-activation";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../../common/Application";

const RecruitAuth = (props) => {
    const [isLaunch, setIsLaunch] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        let code = ChuangShiOS.Util.getParameter("code");
        if (code) {
            handleLogin(code);
        }

        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    const handleLogin = (code) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/edu/recruit/parent/consumer/mobile/v1/mp/login",
            data: {
                code: code,
                recruitTermId: props.match.params.recruitTermId,
            },
            success: (response) => {
                localStorage.setItem("edu_recruit_" + props.match.params.recruitTermId, response.data.token);

                setTimeout(() => {
                    ChuangShiOS.Util.redirectTo(props, {
                        url: "/edu/recruit/my/" + props.match.params.recruitTermId,
                    });
                }, 100);
            },
            error: (response) => {
                setIsLaunch(true);
                setIsError(true);
            },
            complete: (response) => {},
        });
    };

    return (
        <ChuangShiOS.Page>
            <ChuangShiOS.Content isLaunch={isLaunch} isError={isError}></ChuangShiOS.Content>
        </ChuangShiOS.Page>
    );
};

RecruitAuth.propTypes = {};

RecruitAuth.defaultProps = {};

export default withRouter(RecruitAuth);
